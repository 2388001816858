import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { isMobile } from "react-device-detect";

import Page from "../../components/layout/Page"
import SEO from "../../components/seo"
import GoogleImage from "../../components/GoogleImage"
import HoverButton from "../../components/buttons/HoverButton"

import PageHeader from "../../components/layout/PageHeader"
import { devices } from "../../settings"

const Items = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  @media ${devices.mobile} {
    display: block;
  }

  li {
    padding: 5px;
    margin: 5px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 6px 10px #ccc;
    max-width: 400px;

    @media ${devices.mobile} {
      margin-bottom: 15px;
    }

    a {
      color: black;
      display: flex;
      flex-flow: column nowrap;      
      > * {
        flex: none;
      }
      p {
        min-height: 90px;

        @media ${devices.mobile} {
          min-height: auto;
        }        
      }
      button {
        flex: auto;
        align-self: flex-end;
        margin-top: auto;
      }
    }

    .image {
      height: 205px;
      overflow: hidden;
    }

    h2 {
      font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
      margin-top: 0px;
    }
  }
`

const AccessoriesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      accessories: allMysqlAccesories(filter: {title: {ne: "Rush Fee"}}) {
        edges {
            accessory: node {
                accessory_id
                product_id
                product_title
                name
                title
                description
                image
            }
        }
      }
    }
  `)

  function getShopTitle(title) {
    const parts = title.split(" ");

    return parts[parts.length-1]
  }

  return (
    <>
      <SEO page="accessories" title="Accessories" />
      <Page
        breadcrumb={[
          {title: "Home", url:"/"},
          {title: "Products", url:"/products/"},
          {title: "Accessories", url:null},
        ]}
      >
        <PageHeader 
          title="Sign Accessories"
        />

        <Items>
        {data.accessories.edges.map(({accessory}) => (
            <li key={accessory.accessory_id}>
              <Link to={"/products/accessories/" + accessory.name + "/"}>
                <h2>{accessory.title}</h2>

                <div className="image">
                  <GoogleImage root src={accessory.image} required lazy alt={accessory.title} width={isMobile ? 313:368} height={isMobile ? 195:230} />
                </div>

                <p>
                  {accessory.description}
                </p>

                <br />

                <HoverButton 
                  color="blue"
                  label={"Shop " + getShopTitle(accessory.title)}
                  wide
                />
              </Link>
            </li>
        ))}
        </Items>
      </Page>
    </>
  )
}

export default AccessoriesPage
